import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PopulateMaps } from './strixMaps';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function TabWithPanels() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: { xs: 320, sm: 480, md: '100%' }, bgcolor: 'background.paper', justifyContent: 'center', display: 'flex' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="Central Campus" {...a11yProps(0)} />
                    <Tab label="Lorehold College" {...a11yProps(1)} />
                    <Tab label="Prismari College"  {...a11yProps(2)} />
                    <Tab label="Quandrix College"  {...a11yProps(3)} />
                    <Tab label="Silverquill College" {...a11yProps(4)} />
                    <Tab label="Witherbloom College" {...a11yProps(5)} />
                    <Tab label="Other" {...a11yProps(6)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {PopulateMaps('central')}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {PopulateMaps('lorehold')}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {PopulateMaps('prismari')}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {PopulateMaps('quandrix')}
            </TabPanel>
            <TabPanel value={value} index={4}>
                {PopulateMaps('silverquill')}
            </TabPanel>
            <TabPanel value={value} index={5}>
                {PopulateMaps('witherbloom')}
            </TabPanel>
            <TabPanel value={value} index={6}>
                {PopulateMaps('other')}
            </TabPanel>
        </Box>
    );
}
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Copyright } from "./copyright";
import Typography from "@mui/material/Typography";


export function Footer() {
    return (
      <Box
        className='footer'
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1">iiomi.xyz by iiomi</Typography>
          <Copyright />
        </Container>
      </Box>
    );
  }
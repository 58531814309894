import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter,} from 'react-router-dom';
import HeaderAppBar from '../components/appbar';
import { Footer } from '../components/footer';
import Homepage from './Homepage';
import { FoundryPlayerSettings } from './FoundrySettingsPlayer';
import { FoundryUsage } from './FoundryUsage';
import { StrixhavenMaps } from './StrixhavenMaps';

function Page() {
  return (
    <BrowserRouter>
      <div className='site'>
        <div className='header'>{HeaderAppBar()}</div>
        <div className='content'>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path="/PlayerSettings" element={<FoundryPlayerSettings />} />
            <Route path="/FoundryUsage" element={<FoundryUsage />} />
            <Route path="/StrixMap" element={<StrixhavenMaps />} />
            <Route path='/*' element={<Homepage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

const Loader = () => (
  <div>
    <div>Loading...</div>
  </div>
);

export default function Router() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}

import Typography from "@mui/material/Typography";

export function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary">
        {"Copyright © "}
        iiomi.xyz {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }
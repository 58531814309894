import * as React from 'react';
import {Link} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import LinkMUI from '@mui/material/Link/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";


type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function HeaderAppBar() {
  const [menuState, setMenuState] = React.useState({ left: false });
  
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setMenuState({ ...menuState, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        
        <LinkMUI underline='hover' color='inherit'>
        <Link to={'/'} className='linkTo'>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText className='mainPageLink'>Homepage</ListItemText>
            </ListItemButton>
          </ListItem>
          </Link>
        </LinkMUI>
        
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemText>FOUNDRY</ListItemText>
        </ListItem>
        <LinkMUI href="/PlayerSettings" underline='none' color='inherit'>
          <ListItem>
            <ListItemButton>
              <ListItemText> Player Settings </ListItemText>
            </ListItemButton>
          </ListItem>
        </LinkMUI>
        <LinkMUI href="/FoundryUsage" underline='none' color='inherit'>
          <ListItem>
            <ListItemButton>
              <ListItemText>Foundry Usage Guide</ListItemText>
            </ListItemButton>
          </ListItem>
        </LinkMUI>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemText sx={{ textTransform: 'uppercase'}}>Strixhaven</ListItemText>
        </ListItem>
        <LinkMUI href="/" underline='none' color='inherit'>
          <ListItem>
            <ListItemButton>
              <ListItemText>Characters</ListItemText>
            </ListItemButton>
          </ListItem>
        </LinkMUI>
        <Link to={'/StrixMap'} className='linkTo'>
        <LinkMUI underline='none' color='inherit'>
          <ListItem>
            <ListItemButton>
              <ListItemText>Maps</ListItemText>
            </ListItemButton>
          </ListItem>
        </LinkMUI>
        </Link>
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
        <div>
            {(['left'] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  onClick={toggleDrawer(anchor, true)}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={menuState[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>
          <Typography align='center' variant='h1' component='div' sx={{ flexGrow: 1, fontWeight: 500 }}>
            IIOMI.XYZ
          </Typography>
          <div className='invisible'></div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import EventIcon from '@mui/icons-material/Event';
import { HomepageGrid } from '../components/homepageGrid';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Homepage() {
  return (
    <div className='homepage'>
      <Typography variant='h1' align='center' className='title'>Iiomi's Tome of Everything</Typography>
      <Typography variant='h3' align='center' className='subtitle'>Currently under heavy construction.</Typography>
      {HomepageGrid()}
      <Typography variant='h2' align='center'>Upcoming Sessions:</Typography>
      <Typography variant='h4' align='center'>WIP</Typography>
    </div>
  );
}


function UpcomingSessionCard() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container alignItems='center' spacing={2} p={2}>
        <Grid item xs>
          <Card>
            <CardMedia
              component='img'
              height='140'
              alt='Phandelver Logo'
            />
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color='text.secondary'
                gutterBottom
              >
                DnD5e
              </Typography>
              <Typography gutterBottom variant='h5' component='div'>
                Lost Mine of Phandelver
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <EventIcon />
                <Typography variant='subtitle2' color='text.secondary' pl={1}>
                  Fri. 24.06.2022 {'\n'} 19:00 CEST
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card>
            <CardMedia
              component='img'
              height='140'
              alt='CoS Logo'
            />
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color='text.secondary'
                gutterBottom
              >
                DnD5e
              </Typography>
              <Typography gutterBottom variant='h5' component='div'>
                Curse of Strahd
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <EventIcon />
                <Typography variant='subtitle2' color='text.secondary' pl={1}>
                  Sun. 26.06.2022 {'\n'} 17:00 CEST
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Homepage;

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Grid from '@mui/system/Unstable_Grid';

export function PopulateMaps(key: string) {

    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState("");

    switch (key) {
        case 'central':
            return (
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Biblioplex </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> The center of Strixhaven is the Biblioplex, the university's main library. At the heart of the library is a snarl,
                                    a tangle in the fabric of magic, that glows like a miniature sun in the grand Hall of Oracles.
                                    A star arch called the Dawnbow curves across the sky above the library, marking its location as a center of mystic learning. </Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/BiblioplexF1.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/BiblioplexF1.webp')}
                                        srcSet={require('../../media/MapsWebp/BiblioplexF1.webp')}
                                        alt={'Biblioplex Floor 1'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Ground Floor'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Firejolt Café </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> At the edge of Archway Commons and just outside the Biblioplex stands the cozy Firejolt Café, where students cram both information and caffeine into themselves before exams.
                                    The Firejolt has a reputation as a hangout for first-year students, but older students from all five colleges can be seen inside at all hours. </Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/BiblioplexF1.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/BiblioplexF1.webp')}
                                        srcSet={require('../../media/MapsWebp/BiblioplexF1.webp')}
                                        alt={'Firejolt Café'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Firejolt Café'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Bow's End Tavern </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> At the western end of the Dawnbow, where it meets the ground, stands a smaller university landmark: Bow's End Tavern,
                                    which serves as a hangout for older students. Most patrons come for the live music, deliciously greasy food, and various potions and beverages.
                                    The tavern's manager is a kind but strict orc named Tulk "the Bulk" Tusktooth. </Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/bowsEnd.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/bowsEnd.webp')}
                                        srcSet={require('../../media/MapsWebp/bowsEnd.webp')}
                                        alt={'Bow\'s End Tavern'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Bow\'s End Tavern'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Captain Dapplewing's Manor </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> Tucked at the end of a leafy path on the central campus, Captain Dapplewing's Manor is a quiet relaxation spot for elite faculty as well as a place of academic meditation and counseling for their students.
                                    The manor dates to the earliest days of Strixhaven, when the owlin mage and military veteran Captain Raymous Dapplewing held faculty appointments in both Lorehold and Silverquill Colleges.
                                    Wanting a quiet place to retire in the evenings—and to host lavish dinner-parties-turned-colloquiums—the professor used his significant military pension to build this manor house on campus. </Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer', display: 'inline-flex', marginRight: '15px' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/DappleF1.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/DappleF1.webp')}
                                        srcSet={require('../../media/MapsWebp/DappleF1.webp')}
                                        alt={'Captain Dapplewing\'s Manor Floor 1'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Ground Floor'}
                                    />
                                </ImageListItem>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer', display: 'inline-flex', marginRight: '15px' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/DappleF2.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/DappleF2.webp')}
                                        srcSet={require('../../media/MapsWebp/DappleF2.webp')}
                                        alt={'Captain Dapplewing\'s Manor Floor 2'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'First Floor'}
                                    />
                                </ImageListItem>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer', display: 'inline-flex' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/DappleF3.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/DappleF3.webp')}
                                        srcSet={require('../../media/MapsWebp/DappleF3.webp')}
                                        alt={'Captain Dapplewing\'s Manor Floor 3'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Attic'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Dormitory 42 </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> Description </Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/Dorms.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/Dorms.webp')}
                                        srcSet={require('../../media/MapsWebp/Dorms.webp')}
                                        alt={'Dormitory 42'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Dormitory 42'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> The Diamond Grove </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> Description </Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/tdg.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/tdg.webp')}
                                        srcSet={require('../../media/MapsWebp/tdg.webp')}
                                        alt={'The Diamond Grove'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'The Diamond Grove'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box className={open ? 'fotoZoom fotoZoomShow' : 'fotoZoom'} component="span" sx={{ border: '1px black' }} justifyContent='center'>
                        <img src={url} height='100%' className='foto' alt='that what was clicked on' />
                        <CloseIcon className='closeIcon' onClick={() => setOpen(false)} />
                    </Box>
                </Grid>
            )
        case 'lorehold':
            return (
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Khenra Hideout </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> Description </Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/hideoutKhenra.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/hideoutKhenra.webp')}
                                        srcSet={require('../../media/MapsWebp/hideoutKhenra.webp')}
                                        alt={'Khenra Hideout'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Khenra Hideout'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box className={open ? 'fotoZoom fotoZoomShow' : 'fotoZoom'} component="span" sx={{ border: '1px black' }} justifyContent='center'>
                        <img src={url} height='100%' className='foto' alt='that what was clicked on' />
                        <CloseIcon className='closeIcon' onClick={() => setOpen(false)} />
                    </Box>
                </Grid>
            )
        case 'prismari':
            return (
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> No Prismari Maps, yet! </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> There are currently no maps avaliable for Prismari campus. </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box className={open ? 'fotoZoom fotoZoomShow' : 'fotoZoom'} component="span" sx={{ border: '1px black' }} justifyContent='center'>
                        <img src={url} height='100%' className='foto' alt='that what was clicked on' />
                        <CloseIcon className='closeIcon' onClick={() => setOpen(false)} />
                    </Box>
                </Grid>
            )
        case 'quandrix':
            return (
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                            <Typography variant='h1'> No Quandrix Maps, yet! </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> There are currently no maps avaliable for Quandrix campus. </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box className={open ? 'fotoZoom fotoZoomShow' : 'fotoZoom'} component="span" sx={{ border: '1px black' }} justifyContent='center'>
                        <img src={url} height='100%' className='foto' alt='that what was clicked on' />
                        <CloseIcon className='closeIcon' onClick={() => setOpen(false)} />
                    </Box>
                </Grid>
            )
        case 'silverquill':
            return (
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                            <Typography variant='h1'> No Silverquill Maps, yet! </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> There are currently no maps avaliable for Silverquill campus. </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box className={open ? 'fotoZoom fotoZoomShow' : 'fotoZoom'} component="span" sx={{ border: '1px black' }} justifyContent='center'>
                        <img src={url} height='100%' className='foto' alt='that what was clicked on' />
                        <CloseIcon className='closeIcon' onClick={() => setOpen(false)} />
                    </Box>
                </Grid>
            )
        case 'witherbloom':
            return (
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Sedgemoor Excursion </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> Description </Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/sedgemoor1.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/sedgemoor1.webp')}
                                        srcSet={require('../../media/MapsWebp/sedgemoor1.webp')}
                                        alt={'Sedgemoor Excursion'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Sedgemoor Excursion'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box className={open ? 'fotoZoom fotoZoomShow' : 'fotoZoom'} component="span" sx={{ border: '1px black' }} justifyContent='center'>
                        <img src={url} height='100%' className='foto' alt='that what was clicked on' />
                        <CloseIcon className='closeIcon' onClick={() => setOpen(false)} />
                    </Box>
                </Grid>
            )
        case 'other':
            return (
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Vess Mansion </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> Description</Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/vessMansion.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/vessMansion.webp')}
                                        srcSet={require('../../media/MapsWebp/vessMansion.webp')}
                                        alt={'Vess Mansion'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Vess Mansion'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Necropolis </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> Description </Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/necropolis.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/necropolis.webp')}
                                        srcSet={require('../../media/MapsWebp/necropolis.webp')}
                                        alt={'Necropolis'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Necropolis'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Maze </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> Description</Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/mazeDark.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/mazeDark.webp')}
                                        srcSet={require('../../media/MapsWebp/mazeDark.webp')}
                                        alt={'Maze'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Maze'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant='h1'> Temple - Lost to Time </Typography>
                                <Divider />
                                <Typography variant='body2' paddingTop={'inherit'} paddingBottom={'inherit'}> Description</Typography>
                                <ImageListItem sx={{ width: '20%', cursor: 'pointer' }} onClick={() => [console.log('Clicked'), setOpen(true), setUrl(require('../../media/MapsWebp/SecretShrineNight.webp'))]}>
                                    <img
                                        src={require('../../media/MapsWebp/SecretShrineNight.webp')}
                                        srcSet={require('../../media/MapsWebp/SecretShrineNight.webp')}
                                        alt={'Temple'}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={'Temple'}
                                    />
                                </ImageListItem>
                                <Typography variant='body2' paddingTop={'inherit'}> HQ & VTT Download - Coming soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box className={open ? 'fotoZoom fotoZoomShow' : 'fotoZoom'} component="span" sx={{ border: '1px black' }} justifyContent='center'>
                        <img src={url} height='100%' className='foto' alt='that what was clicked on' />
                        <CloseIcon className='closeIcon' onClick={() => setOpen(false)} />
                    </Box>
                </Grid>
            )
        default:
            return (
                <div>Oops, something went wrong!</div>
            )
    }
}
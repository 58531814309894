import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2';
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Link from "@mui/material/Link";
//import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,

}));

const NestedItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#313539' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ':hover': {
        backgroundColor: '#595d63',
        cursor: 'pointer'
    }
}));

const DisabledNestedItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1d1f22' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function HomepageGrid() {
    const [name, setName] = React.useState(' ');
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const authOpen = () => setOpen(true);
    const authClose = () => setOpen(false);

    const downClose = () => setOpen1(false);

    const handleInput = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setName(event.target.value);
    };

    const checkvalue = () => {
        if (name === 'tomesforeveryone') {
            setOpen1(true);
            setOpen(false);
        } else {
            setOpen(false);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} padding={2} maxWidth={'100%'} margin={0}>
                <Grid xs={12} sm={6} xl={3}>
                    <Item>
                        <Typography variant='h1' padding={1}>Foundry</Typography>
                        <Stack spacing={2}>
                            <Link href='https://foundry.iiomi.xyz/' underline='none' color='inherit' target='_blank' rel='noopener noreferrer'>
                                <NestedItem>Foundry</NestedItem>
                            </Link>
                            <Link href='/PlayerSettings' underline='none' color='inherit'>
                                <NestedItem>Player Settings</NestedItem>
                            </Link>
                            <Link href='/FoundryUsage' underline='none' color='inherit'>
                                <NestedItem>
                                    Foundry Usage Guide
                                </NestedItem>
                            </Link>
                            <DisabledNestedItem>Gamemaster Settings</DisabledNestedItem>
                        </Stack>
                    </Item>
                </Grid>
                <Grid xs={12} sm={6} xl={3}>
                    <Item>
                        <Typography variant='h1' padding={1} >Strixhaven</Typography>
                        <Stack spacing={2}>
                            <NestedItem>
                                Characters
                            </NestedItem>
                            <Link href='/StrixMap' underline='none' color='inherit'>
                            <NestedItem>Maps</NestedItem>
                            </Link>
                            <DisabledNestedItem>TBD</DisabledNestedItem>
                            <DisabledNestedItem>TBD</DisabledNestedItem>
                        </Stack>
                    </Item>
                </Grid>
                <Grid xs={12} sm={6} xl={3}>
                    <Item>
                        <Typography variant='h1' padding={1}>Curse of Strahd</Typography>
                        <Stack spacing={2}>
                            <DisabledNestedItem>TBD</DisabledNestedItem>
                            <DisabledNestedItem>TBD</DisabledNestedItem>
                            <DisabledNestedItem>TBD</DisabledNestedItem>
                            <DisabledNestedItem>TBD</DisabledNestedItem>
                        </Stack>
                    </Item>
                </Grid>
                <Grid xs={12} sm={6} xl={3}>
                    <Item>
                        <Typography variant='h1' padding={1}>Other</Typography>
                        <Stack spacing={2}>
                            <NestedItem onClick={authOpen} > Downloadables </NestedItem>
                            <Link href='https://5e.tools/' underline='none' color='inherit' target='_blank' rel='noopener noreferrer'>
                                <NestedItem className='stacklink'>5e.tools</NestedItem>
                            </Link>
                            <DisabledNestedItem>TBD</DisabledNestedItem>
                            <DisabledNestedItem>TBD</DisabledNestedItem>
                        </Stack>
                    </Item>
                </Grid>
            </Grid>
            <Modal open={open} onClose={authClose} aria-labelledby='authetication'>
                <Box>
                    <Typography id='authetication' variant='h6' component='h2'>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }} p={2}>
                            <Typography variant='subtitle1'>Please Autheticate:</Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <TextField
                                    id='standard-password-input'
                                    label='Password'
                                    type='password'
                                    autoComplete='current-password'
                                    variant='standard'
                                    onChange={handleInput}
                                />
                                <Button
                                    onClick={checkvalue}
                                    variant='outlined'
                                    color='inherit'
                                    sx={{ marginLeft: 'auto' }}
                                >
                                    Autheticate
                                </Button>
                            </div>
                        </Box>
                    </Typography>
                </Box>
            </Modal>
            <Modal open={open1} onClose={downClose} aria-labelledby='downloadables'>
                <Box>
                    <Typography id='downloadables' variant='h6' component='h2'>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }} p={2}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >

                                <Typography variant='subtitle1' color={'primary'}>
                                    Player's Handbook
                                </Typography>

                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >

                                <Typography variant='subtitle1' color={'primary'}>
                                    Make Your Character Matter
                                </Typography>

                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >

                                <Typography variant='subtitle1' color={'primary'}>
                                    General Character Sheet
                                </Typography>

                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >

                                <Typography variant='subtitle1' color={'primary'}>
                                    Specialized Character Sheets
                                </Typography>

                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Typography variant='subtitle1' color={'primary'}>
                                    Blade Runner
                                </Typography>

                            </div>
                        </Box>
                    </Typography>
                </Box>
            </Modal>
        </Box>
    );
}
import TabWithPanels from "../components/StrixMap/topTab";
import Typography from '@mui/material/Typography';


export function StrixhavenMaps() {
    return (
        <div className="homepage">
            <Typography variant="h1" align='center' padding={'20px'}>Strixhaven Maps</Typography>
            {TabWithPanels()}
        </div>
    )

}
import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({ 
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 'calc(15px + 0.390625vw)',
                },
                h1: {
                    fontSize: '1.8rem'
                },
                h2: {
                    fontSize: '1.5rem'
                },
                h3: {
                    fontSize: '1.2rem'
                },
                h6: {
                    fontSize: '16px',
                },
                body2:{
                    fontSize: '16px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: '16px'
                }
            }
        }
    },
    palette: {
        mode: 'dark',
      },
});

export default theme;
